import gsap from "gsap";

const 
isMobile = window.innerWidth < 901,
options = {
    root: null,
    rootMargin: isMobile? '0px':'300px',
    threshold: isMobile? 0.15 : 0.75
};



  export const observer = new IntersectionObserver(function(entries, observer) {
    entries.forEach(entry => {
      if(entry.isIntersecting) {
          let img = entry.target.querySelector("img");
          img = img === null? entry.target.querySelector("iframe") : img;
          const image = entry.target.querySelector(".image");
          const text = entry.target.querySelector(".text");
          gsap.to(image,{"--width" : "80%"});
          gsap.fromTo(text,{autoAlpha: 0, y: -100},{autoAlpha: 1, y: 0});
        if(entry.target.classList.contains("reverse")){
            gsap.fromTo(img,{xPercent:100, autoAlpha: 0},{xPercent:0, autoAlpha:1,delay:0.25});
        }else{
            gsap.fromTo(img,{xPercent:-100, autoAlpha: 0},{xPercent:0, autoAlpha:1,delay:0.25});
        }
        observer.unobserve(entry.target);
      }
    });
  }, options);
